import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  sessionPagination: {},
  sessionChatPagination: {},
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionPagination: (state, action) => {
      state.sessionPagination = action.payload;
    },
    setSessionChatPagination: (state, action) => {
      state.sessionChatPagination = action.payload;
    },
  },
});

export const { setSessionPagination,setSessionChatPagination } = sessionSlice.actions;

export default sessionSlice.reducer;
